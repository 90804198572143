.uploaded-items-list {
	overflow-y: auto;
	max-height: 200px;
}

.uploaded-items-list ul {
	list-style: none;
	padding: 0;
}

.uploaded-items-list li {
	background-color: #f3f4f6;
	padding: 10px;
	margin-bottom: 5px;
	border-radius: 5px;
}
