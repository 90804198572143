.main-layout-navbar {
	padding: 33px 42px;
	border-bottom: 1px solid #ededed;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.main-layout-navbar__logo {
	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
}
