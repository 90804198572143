.file-viewer-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5); // Dimmed background
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000; // Ensure it's above other content
}

.file-viewer-overlay__viewer-container {
	width: 80%; // Adjust based on your preference
	max-width: 1000px; // Maximum size of the PDF viewer
	height: 90vh; // Adjust based on your preference
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	overflow: hidden; // Ensure the iframe stays within bounds
	min-height: 500px;
	min-width: 800px;

	@media only screen and (max-width: 768px) {
		min-width: 100%;
		max-width: 100%;
	}
}

.file-viewer-overlay__viewer-container__iframe {
	width: 100%;
	height: 100%;
	border: none;
}

#pdf-controls {
	display: flex;
	justify-content: flex-end;
	background-color: unset;
	box-shadow: unset;
}

#pdf-pagination-info {
	color: black;
}
#pdf-pagination-prev,
#pdf-pagination-next {
	color: black;

	background-color: unset;
}

#pdf-controls > #pdf-download,
#pdf-controls > #pdf-zoom-out,
#pdf-controls > #pdf-zoom-in,
#pdf-controls > #pdf-zoom-reset,
#pdf-controls > #pdf-toggle-pagination {
	display: none;
}
#pdf-page-wrapper {
	margin: 0;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
	display: none;
}

.file-viewer-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 10px;
	margin-top: 10px;
	border-bottom: 3px solid lightgrey;

	@media only screen and (max-width: 768px) {
		flex-flow: column;
		justify-content: center;
		align-items: flex-start;
		padding-left: 20px;
		padding-bottom: 10px;
	}
}

.file-viewer-header__file-name {
	margin-left: 50px;
	font-family: "Avenir Next";
	font-size: larger;

	@media only screen and (max-width: 768px) {
		font-size: 18px;
		margin-left: 0px;
	}
}

.file-viewer-header__actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding-right: 20px;
}

.file-viewer-header__actions--btn {
	margin-right: 10px;
	margin-top: 0;
	max-width: 45px;
	line-height: 1px;

	@media only screen and (max-width: 768px) {
		width: 100px;
	}
}

.file-viewer-spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.file-viewer-error-render {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.file-viewer-unpreviewable-render {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.file-viewer-unpreviewable-render__icon {
	margin-right: 10px;
}
