/* Sidebar.css */

.sidebar {
	border-right: 1px solid #ededed;
	height: 100vh;
	width: 304px;
	z-index: 2;
	background-color: #fff;
	position: fixed;

	@media only screen and (max-width: 768px) {
		display: none;
	}
}

.sidebar__header {
	padding: 44px 38px 41px 38px;
}

.sidebar__body__tab__txt {
	font-weight: 600;
	font-size: 16px;
	font-family: "Avenir Next";
	color: #9b9b9b;
	margin-left: 25px;
}

.sidebar__body__tab {
	display: flex;
	align-items: center;

	border-top: 1px solid #ededed;
	padding: 31px 43px;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
		border-left: 6px solid #1e6efe;
		padding-left: 37px;

		.sidebar__body__tab__txt {
			color: #1e6efe;
		}
	}
}

.sidebar__body__tab--active {
	border-left: 6px solid #1e6efe;
	padding-left: 37px;
	.sidebar__body__tab__txt {
		color: #1e6efe;
	}
}
