.file-table-container {
	margin: 20px;
}

.file-table {
	width: 100%;
	border-collapse: collapse;
	margin-top: 10px;
}

.documents-table {
	margin-top: 36px;
}

.documents-table-header {
	border: none;
	background-color: none;
}

.documents-table-head-th {
	font-family: "Avenir Next";
	font-weight: 400;
	border: none !important;
	font-size: 16px;
	text-align: left;

	@media only screen and (max-width: 768px) {
		font-size: 14px;
		font-weight: 500;
	}
}

.documents-table-head-th--name {
	width: 50%;
	padding-left: 55px !important;
}

.documents-table-head-th--date,
.documents-table-head-th--time {
	width: 15%;
}

.documents-table-head-th--file-size {
	width: 8%;
}

.documents-table-head-th--file-size,
.documents-table-head-th--time,
.documents-table-row-td--time,
.documents-table-row-td--file-size {
	@media only screen and (max-width: 768px) {
		display: none;
	}
}

.documents-table-head-th--action {
	width: 5%;
}

.documents-table-row-td {
	font-size: 14px;
	font-family: "Avenir Next";
	font-weight: 500;
	color: #5e5e5e;
	border: none !important;
	border-bottom: 1px solid #ededed !important;
	padding-top: 12px;
	padding-bottom: 12px;
}

.documents-table-row {
	&.active-row {
		background-color: #f0f0f0; // Set your desired background color for the active row
	}
}

.documents-table-row:hover {
	cursor: pointer;
	opacity: 0.8;
	background-color: #f9f9f9;
}

.documents-table-row-td--name {
	display: flex;
	align-items: center;
	padding-left: 15px;
}

.documents-table-row-td--name__txt {
	@media only screen and (max-width: 768px) {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 0;
		/* This helps in some flexbox scenarios */
		max-width: 180px;
	}
}

.documents-table-row-td--name__file-icon {
	margin-right: 23px;
}

.documents-table-row--category-divider {
	height: 30px;
	background-color: #ededed;
}

.documents-table-row--category-divider__label {
	font-weight: 700;
	padding-left: 20px;
	font-family: "Avenir Next";
	font-size: 16px;
}

.file-not-found {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	margin-top: 100px;
	font-size: 20px;
	color: gray;
	width: 50%;
}

.file-not-found__icon {
	width: 40px;
	height: 40px;
	fill: gray !important;
}

.table-search-text-field {
	padding: 0 10px;
	font-size: 14px;
	border: 1px #ccc solid;
	border-radius: 5px;
	float: left;
	margin-top: 20px;
	margin-bottom: 40px;
}
