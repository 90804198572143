.text-field--primary__input {
	width: 305px;
	height: 50px;
	border: none;
	border-bottom: 1px solid #dfdfdf;

	font-size: 16px;

	&:focus {
		outline: none;
		border-bottom: 2px solid #000000;
	}
}
