.new-acc-transition-view-1 {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}

.new-acc-transition-view-1__title,
.new-acc-transition-view-2__title {
	font-size: 21px;
	font-weight: 700;
	font-family: "Avenir Next";
}

.new-acc-transition-view-1__icon {
	width: 50px;
	margin-top: 50px;
	margin-right: 10px;
}

.new-acc-transition-view-2 {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}
