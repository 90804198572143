.create-password {
	display: flex;
	flex-flow: column;
}

.create-password__form {
	display: flex;
	flex-flow: column;
	align-items: center;

	@media only screen and (max-width: 768px) {
		padding: 20px 25px;
	}
}

.create-password__form__title {
	font-family: "Avenir Next";
	font-weight: 600;
	font-size: 21px;
}

.create-password__form__inputs {
	margin-top: 47px;
}

.create-password__form__inputs__input {
	margin-top: 24px;
}
