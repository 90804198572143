.documents {
	padding-left: 348px;
	padding-top: 118px;
	padding-right: 100px;
	width: 100%;
	height: 100vh;
	background-color: rgb(252, 252, 252);
	z-index: 0;
	position: relative;

	@media only screen and (max-width: 768px) {
		padding-left: 20px;
		padding-right: 0px;
	}
}
