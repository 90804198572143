.dropzone {
	border-radius: 5px;

	text-align: center;
	cursor: pointer;

	display: flex;
	flex-flow: column;
}

.dropzone {
	position: absolute;
	top: 0;
	left: 0;
	width: calc(100% - 304px);
	margin-left: 304px;
	height: 100%;
	z-index: 10; // Ensure it covers other content
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.9); // Semi-transparent or any color you prefer
	border: none; // Adjust based on your design

	@media only screen and (max-width: 768px) {
		margin-left: 0px;
		width: auto;
		z-index: 3;
	}
}

.dropzone__info {
	display: flex;
	align-items: center;
	flex-flow: column;
	margin-bottom: 60px;
}

.dropzone__info__upload-icon {
	width: 83px;
}
.dropzone__info__txt {
	font-size: 18px;
	font-family: "Avenir Next";
	font-weight: 600;
	color: #1e6efe;
	margin-bottom: 11px;
	margin-top: 30px;
}

.dropzone__inner {
	display: flex;
	flex-flow: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
	margin-top: 50px;
	max-height: 500px;
	border: 3px dashed #1e6efe;
	padding-top: 100px;
	padding-left: 150px;
	padding-right: 150px;
	border-radius: 20px;

	@media only screen and (max-width: 768px) {
		padding: 50px 20px;
	}
}

.dropzone__footer__upload-btn {
	margin-top: 0px;
}

.dropzone__footer__cancel-btn {
	position: absolute;
	top: 70px;
	right: 130px;

	@media only screen and (max-width: 768px) {
		top: 80px;
		right: 10px;
	}
}
