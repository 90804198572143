.top-navbar {
	border-bottom: 1px solid #ededed;
	height: 73.5px;
	width: 100%;

	z-index: 2;
	background-color: #fff;

	position: fixed;

	width: 100%;
}

.top-navbar__inner {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 100%;

	margin-right: 43px;

	@media only screen and (max-width: 768px) {
		justify-content: space-between;
		margin-right: 20px;
		margin-left: 20px;
	}
}
.top-navbar__inner__toggle,
.top-navbar__inner__toggle--client {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 45px;
	width: 45px;
	border-radius: 50%;
	background-color: #ededed;

	border: 1px solid #ededed;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
}

.top-navbar__inner__toggle--client {
	width: fit-content;
	border-radius: 8px;
	padding-left: 15px;
	padding-right: 15px;
	margin-right: 50px;
}

.top-navbar__inner__toggle--client__txt {
	font-family: "Avenir Next";
	font-size: 16px;
}

.top-navbar__inner__toggle__letter {
	font-family: "Avenir Next";
	font-size: 16px;
	font-weight: 700;
}

// dropdown
.top-navbar__inner__dropdown {
	height: 52px;
	width: 116px;
	border-radius: 8px;
	border: 1px solid #ededed;
	position: absolute;
	margin-top: 115px;
	background-color: #fff;
	right: 50px;

	@media only screen and (max-width: 768px) {
		right: 20px;
	}
}

.top-navbar__inner__dropdown--client {
	width: 100%;
	max-width: 170px;
	max-height: 200px;
	min-width: fit-content;
	min-height: fit-content;

	overflow-y: auto;
	border-radius: 8px;
	border: 1px solid #ededed;
	position: absolute;
	top: 60px;
	margin-right: 90px;
	background-color: #fff;
}

.top-navbar__inner__dropdown__tab__txt {
	display: flex;
	flex-flow: column;

	font-weight: 400;
	font-family: "Avenir Next";
	font-size: 14px;
	padding-left: 12px;
	margin-bottom: 7px;
	background-color: rgb(240, 240, 240);
	height: 40px;

	padding-right: 10px;
	&:hover {
		cursor: pointer;
		background-color: rgb(223, 236, 255);
		opacity: 0.7;
	}
}

.top-navbar__inner__dropdown__tab__icon {
	margin-right: 12px;
}

.top-navbar__inner__dropdown__tab {
	display: flex;
	flex-flow: column;
}

.top-navbar__inner__dropdown__tab__txt--logout {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-between;
}

.top-navbar__inner__dropdown__tab__txt__name {
	font-weight: 600;
}
