.documents-header {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	width: 100%;
}
.documents-header__title {
	font-size: 21px;
	font-family: "Avenir Next";
	font-weight: 600;
}

.documents-header__new-btn {
	display: flex;
	align-items: center;
	justify-content: space-between;

	height: 38px;
	width: 108px;
	border-radius: 19px;
	background-color: #f1f1f1;
	margin-right: 10px;
	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}

	@media only screen and (max-width: 768px) {
		margin-right: 20px;
	}
}

.documents-header__new-btn__txt {
	font-size: 16px;
	font-weight: 500;
	font-family: "Avenir Next";
	margin-right: 25px;
}
.documents-header__new-btn__icon {
	margin-left: 13px;
}

//

.documents-header__edit-btn-container {
	height: 38px;
	width: 100%;
	max-width: 527px;
	border-radius: 19px;

	background-color: #f1f1f1;
	padding-left: 17px;
	display: flex;
	align-items: center;
}

.documents-header__edit-btn-container__inner {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 768px) {
		width: 100%;
	}
}

.documents-header__edit-btn-container__inner__txt {
	font-weight: 500;
	font-size: 15px;
	font-family: "Avenir Next";
	margin-right: 24px;
	margin-left: 17px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 150px; /* This helps in some flexbox scenarios */
	max-width: 150px;

	@media only screen and (max-width: 768px) {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		min-width: 0; /* This helps in some flexbox scenarios */
		max-width: 100px;
	}
}

.documents-header__edit-btn-container__inner__icon--x {
	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
}

.documents-header__edit-btn-container__inner__icon--download,
.documents-header__edit-btn-container__inner__icon--trash,
.documents-header__edit-btn-container__inner__icon--edit {
	width: 18px;
	margin-right: 20px;
	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
}

.documents-header__edit-btn-container__inner__icon--trash {
	width: 16px;
}

.documents-header__edit-btn-container__inner__icon--eye {
	width: 23px;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
}

//transitions
.documents-header__edit-btn-container {
	transition: height 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Use a cubic-bezier easing function */
	height: 0;
	overflow: hidden;
}

.expand {
	height: 38px; /* or specify the max height you want */

	@media only screen and (max-width: 768px) {
		padding-right: 20px;
		margin-right: 20px;
	}
}

.documents-header__edit-btn__actions {
	display: flex;
}

.documents-header__edit-btn-container__inner__x-container {
	display: flex;
	align-items: center;
}

.documents-header__edit-btn-container__inner__x-container__file-name {
	font-size: 14px;
	font-weight: 500;
	font-family: "Avenir Next";
	margin-left: 24px;
	margin-right: 24px;
	color: #525252;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
