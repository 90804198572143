.login__form__inputs__reset-pass-link {
	width: fit-content;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
	}
}

.login__form__inputs__reset-pass {
	display: flex;
	width: 100%;
	font-size: 16px;
	justify-content: flex-end;
	margin-top: 9px;
	font-weight: 500;

	&:hover {
		cursor: pointer;
		opacity: 0.8;
		text-decoration: underline;
	}
}
