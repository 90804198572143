.custom-btn--primary,
.custom-btn--secondary {
	background-color: #000;
	color: #fff;
	height: 45px;
	width: 195px;

	border-radius: 30px;

	font-family: "Avenir Next";
	font-size: 14px;
	font-weight: 600;
	border: none;
	margin-top: 54px;

	&:hover {
		opacity: 0.8;
		cursor: pointer;
	}

	&:disabled {
		background-color: grey;
		cursor: not-allowed;
		opacity: 0.5;
	}
}

.custom-btn--secondary {
	background-color: #fff;
	color: #000;
	border: 1px solid #000;
	height: 45px;
	width: fit-content;
	padding-left: 20px;
	padding-right: 20px;
}
