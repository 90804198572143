$font-family-primary: "Montserrat", sans-serif;

.plaid-landing-page {
	min-height: 100vh;
	background-color: #f3f4f6;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1rem;

	.logo img {
		width: 22rem;
		height: auto;
	}

	.svg-logo {
		width: 25rem;
		height: auto;
	}

	.btn--primary {
		background-color: #000;
		color: #fff;
		height: 3rem;
		width: 14rem;
		border-radius: 30px;
		font-family: $font-family-primary;
		font-size: 16px;
		font-weight: 600;
		border: none;
		cursor: pointer;

		&:hover {
			background-color: #333;
			color: #fff;
		}
	}

	.main-card {
		background-color: white;
		border-radius: 0.5rem;
		box-shadow:
			0 4px 6px -1px rgba(0, 0, 0, 0.1),
			0 2px 4px -1px rgba(0, 0, 0, 0.06);
		padding: 2rem;
		width: 100%;
		max-width: 28rem;
		margin: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2rem;
	}

	.main-card-heading {
		font-family: $font-family-primary;
		font-size: 2rem;
		font-weight: 600;
	}

	.divider {
		width: 100%;
		height: 1px;
		background-color: #d3d3d3;
	}

	.legal-agreements {
		font-family: $font-family-primary;
		font-size: 0.9rem;
		align-content: center;

		br {
			margin-bottom: 0.5rem;
			display: block;
			content: "";
		}
	}
}
