$font-family-primary: "Montserrat", sans-serif;

.plaid-success-page {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
	text-align: center;
	padding: 20px;
}

.plaid-success-page h1 {
	margin-bottom: 20px;
	font-family: $font-family-primary;
	font-size: 4rem;
}

.plaid-success-page p {
	margin-top: 20px;
	font-family: $font-family-primary;
}

.svg-logo {
	width: 30rem;
	height: auto;
}

.plaid-success-page__contact {
	font-family: $font-family-primary;
	font-size: 0.9rem;
}

.plaid-success-page__message {
	font-family: $font-family-primary;
	text-decoration: none;
	font-weight: 600;
	font-size: 1.5rem;
}

.plaid-success-page__close__message {
	font-family: $font-family-primary;
	font-weight: 500;
	font-style: italic;
	font-size: 1rem;
}
